import { Link, useNavigate, useParams } from 'react-router-dom';
import './css/ct.css';
import { useEffect, useState } from 'react';
import axios from 'axios';
import internal from 'stream';
import { IUser } from '../Interfaces/Interfaces';

const UserList = () =>{
    const { page, param } = useParams<{
        page: string;
        param: string;
      }>();

    const authorizeCheck = () => {
        if (localStorage.getItem('adminJwt') === null){
            navigate('/signIn');
        }
    }

    const navigate = useNavigate();
    const modifyUser = (id: number) => {
        navigate('/userPage/' + id);
        //console.log(id);
    }

    const [users, setUsers] = useState<Array<IUser>>();

    const [pageCount, setPageCount] = useState<number>(1);

    const [currentPage, setCurrentPage] = useState<number>(1);

    const [searchValue, setSearchValue] = useState<string>('');

    const setAllUsers = async () => {
        if (localStorage.getItem('adminJwt') !== null){
            const data = { 
                data: localStorage.getItem('adminJwt'),
                page: page,
                searchParam: param
         };
            const response = await axios.post("https://bigdata.name/GetAllUsers", data);
            setUsers(response.data.users);
            setPageCount(response.data.countPages);
            setCurrentPage(response.data.page);
        }
    }

    const displayAllUsers = () => {
        return users?.map(user => {
            let date = new Date(user.registationDate);
            let lastVisit = new Date(user.lastVisit);
            //console.log(user.registationDate)
            return(
                <tr key={user.id}>
                    <th className='table-row'>{user.id}</th>
                    <th className='table-row'>{user.email}</th>
                    <th className='table-row'>{user.balance}$</th>
                    <th className='table-row'>{user.status}</th>
                    <th className='table-row'>
                        {date.toLocaleDateString()}<br></br>
                        {date.toLocaleTimeString()}<br></br>
                    </th>
                    <th className='table-row'>
                        {lastVisit.toLocaleDateString()}<br></br>
                        {lastVisit.toLocaleTimeString()}<br></br>
                    </th>
                    <th><div onClick={() => modifyUser(user.id)} className='modify-button'>Modify</div></th>
                    
                </tr>
            )
        })
    }

    const pageCounter = () => {
        if (currentPage < 5)
        {
            return (
                <div className='page-counter-container'>
                    {pageAvailable(currentPage - 1,'<')}
                    {pageAvailable(1,'1')}
                    {pageAvailable(2,'2')}
                    {pageAvailable(3,'3')}
                    {pageAvailable(4,'4')}
                    {pageAvailable(5,'5')}
                    {pageAvailable(currentPage + 1,'>')}
                </div>
            )
        }
        else{
            if((pageCount - currentPage) > 5){
                return(
                    <div className='page-counter-container'>
                        {pageAvailable(currentPage - 1,'<')}
                        {pageAvailable(currentPage - 2,'')}
                        {pageAvailable(currentPage - 1,'')}
                        {pageAvailable(currentPage,'')}
                        {pageAvailable(currentPage + 1,'')}
                        {pageAvailable(currentPage + 2,'')}
                        {pageAvailable(currentPage + 1,'>')}
                    </div>
                )
                
            }
            else{
                return(
                    <div className='page-counter-container'>
                        {pageAvailable(currentPage - 1,'<')}
                        {pageAvailable(currentPage - 5,'')}
                        {pageAvailable(currentPage - 4,'')}
                        {pageAvailable(currentPage - 3,'')}
                        {pageAvailable(currentPage - 2,'')}
                        {pageAvailable(pageCount - 1,'')}
                        {pageAvailable(currentPage + 1,'>')}
                    </div>
                )
            }

        }
    }

    const RedirectToPage = (url: string) => {
        navigate(url);
        window.location.reload();
    }

    const redirectionString = (search: string) => {
        return "/userList/1/" + (search !== '' ? search : 'none_param')
    }

    const navigatePageString = (num: number) => {
        return "/userList/" + num  + "/" + param
    }

    const searchString = (search: string) => {
        return(
            <div style={{textAlign: 'center'}}>
                <input value={searchValue} onChange={handleSearchChange} className='search-string' placeholder='Search...'></input>
                <div onClick={() => RedirectToPage(redirectionString(search))} className='find-button'>FIND</div>
            </div>
        )
    }

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(event.target.value);
      };

    const pageAvailable = (num: number, symbol: string) => {
        let char = `${num}`;
        if (symbol !== ""){
            char = symbol;
        }
        if (num <= pageCount && num !== 0){
            return(
                <div onClick={() => RedirectToPage(navigatePageString(parseInt(char)))} className='page-counter-elem'>{char}</div>
            )
        }
    }

    useEffect(() => {
        authorizeCheck();
        setAllUsers();
    }, []); 

    return(
        <>
            {searchString(searchValue + "")}
            <table className="users-table">
                <thead>
                    <tr>
                        <th>Id</th>
                        <th>Email</th>
                        <th>Balance</th>
                        <th>Status</th>
                        <th>Registration Date</th>
                        <th>Last Visit</th>
                        <th>Modify</th>
                    </tr>
                </thead>
                <tbody>
                    {displayAllUsers()}
                </tbody>
            </table>
            {pageCounter()}
        </>
    )
}

export default UserList;