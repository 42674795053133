import './css/ct.css'
import eye_icon from '../Images/eye_icon.png'
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { validateStringWithSpecialSymbols } from '../Functions/Functions';

const SignIn = () => {
  const navigate = useNavigate();
  const login = async (Email: string, Password: string) => {
    try {
      if (localStorage.getItem('LogInExpTime') !== null){
        if (MoreThan15Minutes(localStorage.getItem('LogInExpTime') + '')){
          localStorage.removeItem('logIns');
          localStorage.removeItem('LogInExpTime');
        }
      }
      //console.log('email - ' + Email, 'password - ' + Password);
      const data = { email: validateStringWithSpecialSymbols(Email), password: validateStringWithSpecialSymbols(Password) };
      if (localStorage.getItem('logIns') === null || Number.parseInt(localStorage.getItem('logIns') + '') < 6){
        const response = await axios.post("https://bigdata.name/signInAdmin", data);
        const token = response.data.token;
        localStorage.setItem('adminJwt', token.result);
        navigate('/');
        window.location.reload();
        // store the token in localStorage or a cookie
      }
      else{
        if (localStorage.getItem('LogInExpTime') === null){
          let date = new Date();
          localStorage.setItem('LogInExpTime', date.toString());
        }
        alert('log in attempts wasted, try to log in 15 minutes later');
      }

    } catch (error) {
      console.clear();
      if (localStorage.getItem('logIns') === null){
        //console.log('incorrect log in');
        localStorage.setItem('logIns', '1');
      }
      else{
        let num: number = Number.parseInt(localStorage.getItem('logIns') + '');
        localStorage.setItem('logIns', `${num + 1}`);
      }
      alert('Incorrect login or password!');
    }
  };
  const [language, setLanguage] = useState<string>(localStorage.getItem('language') !== null ? localStorage.getItem('language') + '' : 'eng');

    const [emailValue, setEmailtValue] = useState<string>('');
    const [passwordValue, setPasswordtValue] = useState<string>('');
    const [inputType, setInputType] = useState<string>('password');

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmailtValue(event.target.value);
      };

    const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPasswordtValue(event.target.value);
      };

    const handleEyeClick = () => {
      setInputType(inputType === 'password' ? 'text' : 'password');
    };

    const MoreThan15Minutes = (val1: string) => {
      let dateNow = new Date();
      const startDate = new Date(val1); // Replace this with your start date
      const timeDifferenceInMilliseconds = dateNow.getTime() - startDate.getTime();
      const timeDifferenceInMinutes = timeDifferenceInMilliseconds / (1000 * 60);
      return timeDifferenceInMinutes >= 15;
    }
  
    
    return(
        <div className="auth-frame">
            <div className='site-logo'>INFO<span style={{color:'limegreen'}}>MARKET</span></div><br></br>
            <input value={emailValue} onChange={handleEmailChange} className='auth-input' type="text" placeholder='Email...' />
            <div className='password-container'>
                <input value={passwordValue} onChange={handlePasswordChange} className='auth-input password-input' type={inputType} placeholder='Password...'></input>
                <img onClick={handleEyeClick} className='eye-icon' src={eye_icon}></img>
            </div>
            <div onClick={() => {login(emailValue, passwordValue)}} className='auth-button'>Sign In</div>
        </div>
    )
}

export default SignIn;