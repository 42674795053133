import { IMessageItem } from '../../Interfaces/Interfaces';
import './css/ct.css';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { translateEngRusEsp, validateStringWithSpecialSymbols } from '../../Functions/Functions';
import { useNavigate, useParams } from 'react-router-dom';

const Chat = () => {
    const navigate = useNavigate();
    const authorizeCheck = () => {
        if (localStorage.getItem('adminJwt') === null){
            navigate('/signIn');
        }
    }
    

    const { id } = useParams<{
        id: string;
      }>();

    const [language, setLanguage] = useState<string>(localStorage.getItem('language') !== null ? localStorage.getItem('language') + '' : 'eng');

    const [messageText, setMessageText] = useState<string>('');

    const [messages, setMessages] = useState<Array<IMessageItem>>([]);

    const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMessageText(event.target.value);
    };

    const handleSubmit = () => {
        if (localStorage.getItem('adminJwt') !== null){
            if (messageText !== ''){
                sendMessage(messageText);
                displayMessage(messageText);
                setMessageText(''); 
            }
        }
        else{
            alert('Authorize to send a message');
        }
    }

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
          event.preventDefault();
          handleSubmit();
          (event.target as HTMLInputElement).blur();
        }
    };

    const getAllMessages = async () => {
        try{
            if (localStorage.getItem('adminJwt') !== null){
                const data ={ 
                    data: localStorage.getItem('adminJwt'),
                    userId: id
                }
                const response = await axios.post("https://bigdata.name/GetAllMessagesADMIN", data);
                setMessages(response.data);
            }
        }
        catch(error){
            alert(error);
        }
    }

    const sendMessage = async (text: string) => {
        try{
            if (text.length !== 0 && text !== null){
                const data ={ 
                    data: localStorage.getItem('adminJwt'), 
                    message: validateStringWithSpecialSymbols(text),
                    userId: id
                }
                const response = await axios.post("https://bigdata.name/SendMessageADMIN", data);
            }
        }
        catch(error){
            alert(error);
        }
    }

    const displayAllMessages = (list: Array<IMessageItem>) => {
        return list.map(item => {
            if (item.from === 'user'){
                return (
                    <div key={item.id + 'container'}>
                        <div key={item.id} className='message my-message'>{item.messageText}</div>
                        <div key={item.id + "-seen"} style={{alignSelf: 'flex-end'}}>{item.seen ? 'Seen' : ''}</div>
                    </div>
                ) 
            }
            else{
                return(
                    <>
                        <div key={item.id} className='message admin-message'>{item.messageText}</div>
                        <div key={item.id + "-seen"} style={{marginLeft: '60%'}}>{item.seen ? 'Seen' : ''}</div>
                    </>
                    
                )
            }
        })
    }

    const displayMessage = (text: string) => {
        let message: IMessageItem = {
            id: 'unknown',
            messageText: text,
            from: 'admin',
            to: 'user',
            seen: false
        }
        setMessages(prevState => [...prevState, message]);
    }
    
    useEffect(() => {
        authorizeCheck();
        // Call getAllMessages initially
        getAllMessages();
        // Set up interval to call getAllMessages every 10 seconds
        const interval = setInterval(() => {
          getAllMessages();
        }, 10000);
        // Clean up the interval when the component unmounts
        return () => {
          clearInterval(interval);
        };
      }, []);

    return (    
        <div className='chat-frame'>
            <div id='chat-window'>
                {displayAllMessages(messages)}
            </div>
            <input onKeyDown={handleKeyDown} value={messageText} onChange={handleInput} className='message-input' placeholder={translateEngRusEsp(language, 'Message...', 'Сообщение...', 'Mensaje...')}></input>
            <div onKeyDown={handleKeyDown} onClick={handleSubmit} className='send-button'>{translateEngRusEsp(language, 'Send', 'Отправить', 'Enviar')}</div>
        </div>
    )
}

export default Chat;