import React from 'react';
import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'; 
import Header from './components/Header/Header';
import HomePage from './components/Home/HomePage';
import Footer from './components/Footer/Footer';
import SignIn from './components/Auth/SignIn';
import UserList from './components/Users/UserList';
import UserPage from './components/Users/UserPage/UserPage';
import ChatList from './components/Chat/ChatsList/ChatList';
import OrdersPage from './components/DisplayInformation/OrdersPage';
import Chat from './components/Chat/Chat/Chat';
import TransactionList from './components/TransactionList/TransactionList';
import UploadData from './components/Upload/UploadData';

function App() {
  return (
    <>
      <Router>
        <Header/>
        <div style={{height: '70px'}}></div>
        <div style={{minHeight: '600px'}}>
          <Routes>
            <Route path='/' element={<HomePage/>}/>
            <Route path='/signIn' element={<SignIn/>}/>
            <Route path='/userList/:page/:param' element={<UserList/>}/>
            <Route path='/userPage/:id' element={<UserPage/>}/>
            <Route path='/chatList/:page' element={<ChatList/>}/>
            <Route path='/ordersPage/:id/:page' element={<OrdersPage/>}/>
            <Route path='/chat/:id' element={<Chat/>}/>
            <Route path='/transactionList/:page/:param' element={<TransactionList/>}/>
            <Route path='/uploadData' element={<UploadData/>}/>
          </Routes>
        </div>
        <Footer/>
      </Router>
    </>
  );
}

export default App;
