import { Link, useNavigate } from 'react-router-dom';
import './css/ct.css'

const HomePage = () => {

    const navigate = useNavigate();
    function LogOut() {
        try{
            localStorage.removeItem('adminJwt');
            navigate('/signIn');
            window.location.reload();
        }
        catch(error){
            alert(error);
        }
    }

    const mainButton = () => {
        if (localStorage.getItem('adminJwt') === null){
            return(
                <>
                    <Link to='/signIn' className='homepage-action-button'>SIGN IN</Link>
                </>
            )
        }
        else{
            return(
                <div onClick={LogOut} className='homepage-action-button'>LOG OUT</div>
            )
        }
    }

    return(
        <>
        <div className='homepage-frame'>
            {mainButton()}
        </div>
        </>
    )
}

export default HomePage;