import './css/ct.css'

const Footer = () => {
    return (
        <div className="footer-frame">
            INFO<span style={{color: 'limegreen'}}>MARKET®</span> Copyright ©2023
        </div>
    )
}

export default Footer;