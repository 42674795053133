import { Link, useNavigate, useParams } from 'react-router-dom';
import '../Users/css/ct.css';
import { useEffect, useState } from 'react';
import axios from 'axios';
import internal from 'stream';
import { IUser, ITransactionAdditional } from '../Interfaces/Interfaces';

const TransactionList = () =>{
    const { page, param } = useParams<{
        page: string;
        param: string;
      }>();


    const navigate = useNavigate();
    const modifyUser = (id: number) => {
        navigate('/userPage/' + id);
        //console.log(id);
    }

    const authorizeCheck = () => {
        if (localStorage.getItem('adminJwt') === null){
            navigate('/signIn');
        }
    }

    const [users, setUsers] = useState<Array<ITransactionAdditional>>();

    const [pageCount, setPageCount] = useState<number>(1);

    const [currentPage, setCurrentPage] = useState<number>(1);

    const [searchValue, setSearchValue] = useState<string>(param + "");

    const setAllUsers = async () => {
        if (localStorage.getItem('adminJwt') !== null){
            const data = { 
                data: localStorage.getItem('adminJwt'),
                page: page,
                searchParam: param
         };
            const response = await axios.post("https://bigdata.name/GetAllTransactions", data);
            //console.log(response.data);
            setUsers(response.data.transactions);
            setPageCount(response.data.countPages);
            setCurrentPage(response.data.page);
        }
    }

    const displayAllUsers = () => {
        return users?.map(transaction => {
            let date = new Date(transaction.creationDate);
            return(
                <tr key={transaction.id}>
                    <th className='table-row'>{transaction.id}</th>
                    <th className='table-row'><Link to={'/userPage/' + transaction.userId}>{transaction.user}</Link></th>
                    <th className='table-row'>{transaction.currency.toUpperCase() !== 'GIFT' ? transaction.currency.toUpperCase() : "ADMIN BONUS"}</th>
                    <th style={{fontSize: '20px'}} className='table-row'><a href={transaction.addressHandler}>{transaction.addressHandler}</a>{transaction.currency.toUpperCase() !== 'GIFT' ? '' : "----"}</th>
                    <th className='table-row'>{transaction.pending}</th>
                    <th className='table-row'>{transaction.received}$</th>
                    <th className='table-row'>
                        {date.toLocaleDateString()}<br></br>
                        {date.toLocaleTimeString()}<br></br>
                    </th>                    
                </tr>
            )
        })
    }

    const pageCounter = () => {
        if (currentPage < 5)
        {
            return (
                <div className='page-counter-container'>
                    {pageAvailable(currentPage - 1,'<')}
                    {pageAvailable(1,'1')}
                    {pageAvailable(2,'2')}
                    {pageAvailable(3,'3')}
                    {pageAvailable(4,'4')}
                    {pageAvailable(5,'5')}
                    {pageAvailable(currentPage + 1,'>')}
                </div>
            )
        }
        else{
            if((pageCount - currentPage) > 5){
                return(
                    <div className='page-counter-container'>
                        {pageAvailable(currentPage - 1,'<')}
                        {pageAvailable(currentPage - 2,'')}
                        {pageAvailable(currentPage - 1,'')}
                        {pageAvailable(currentPage,'')}
                        {pageAvailable(currentPage + 1,'')}
                        {pageAvailable(currentPage + 2,'')}
                        {pageAvailable(currentPage + 1,'>')}
                    </div>
                )
                
            }
            else{
                return(
                    <div className='page-counter-container'>
                        {pageAvailable(currentPage - 1,'<')}
                        {pageAvailable(currentPage - 5,'')}
                        {pageAvailable(currentPage - 4,'')}
                        {pageAvailable(currentPage - 3,'')}
                        {pageAvailable(currentPage - 2,'')}
                        {pageAvailable(pageCount - 1,'')}
                        {pageAvailable(currentPage + 1,'>')}
                    </div>
                )
            }

        }
    }

    const RedirectToPage = (url: string) => {
        navigate(url);
        window.location.reload();
    }

    const redirectionString = (search: string) => {
        return "/transactionList/1/" + (search !== '' ? search : 'none_param')
    }

    const navigatePageString = (num: number) => {
        return "/transactionList/" + num  + "/" + param
    }

    const searchString = (search: string) => {
        return(
            <div style={{textAlign: 'center'}}>
                <select value={searchValue} onChange={handleSearchChange} className='search-string'>
                    <option value='none_param'>NONE</option>
                    <option value='btc'>BTC</option>
                    <option value='eth'>ETH</option>
                    <option value='bch'>BCH</option>
                    <option value='ltc'>LTC</option>
                </select><br></br>
               
                <div onClick={() => RedirectToPage(redirectionString(search))} className='find-button'>FIND</div>
            </div>
        )
    }

    const handleSearchChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSearchValue(event.target.value);
      };

    const pageAvailable = (num: number, symbol: string) => {
        let char = `${num}`;
        if (symbol !== ""){
            char = symbol;
        }
        if (num <= pageCount && num !== 0){
            return(
                <div onClick={() => RedirectToPage(navigatePageString(parseInt(char)))} className='page-counter-elem'>{char}</div>
            )
        }
    }

    useEffect(() => {
        authorizeCheck();
        setAllUsers();
    }, []); 

    return(
        <>
            {searchString(searchValue + "")}
            <table className="users-table">
                <thead>
                    <tr>
                        <th>Id</th>
                        <th>User</th>
                        <th>Currency</th>
                        <th>Address Handler</th>
                        <th>Pending</th>
                        <th>Received</th>
                        <th>Creation Date</th>
                    </tr>
                </thead>
                <tbody>
                    {displayAllUsers()}
                </tbody>
            </table>
            {pageCounter()}
        </>
    )
}

export default TransactionList;