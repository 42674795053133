import { Link, useNavigate, useParams } from "react-router-dom";
import './css/ct.css'
import { useEffect, useState } from "react";
import axios from "axios";

interface ITransaction{
    id: string,
    addressHandler: string,
    currency: string,
    pending: string, 
    received: number,
    creationDate: Date
}

const UserPage = () => {
    const { id } = useParams<{
        id: string;
      }>();
      const authorizeCheck = () => {
        if (localStorage.getItem('adminJwt') === null){
            navigate('/signIn');
        }
    }

      const getUser = async () => {
        const data = {
            id: id,
            jwt: localStorage.getItem('adminJwt')
        }
        const response = await axios.post('https://bigdata.name/GetUserInfo', data);
        setEmailtValue(response.data.user.email);
        setBalance(response.data.user.balance);
        setTransations(response.data.transactions);
        setStatus(response.data.user.status === undefined ? 'not active' : response.data.user.status);
    }
    const navigate = useNavigate();
    const [transactions, setTransations] = useState<Array<ITransaction>>([]);

    const [emailValue, setEmailtValue] = useState<string>('xxx@gmail.com');
    const [balance, setBalance] = useState<number>(100.5);
    const [passwordValue, setPasswordValue] = useState<string>('');
    const [status, setStatus] = useState<string>('not active');

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmailtValue(event.target.value);
      };
    
    const handleBalanceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setBalance(parseFloat(event.target.value));
      };
    
    const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPasswordValue(event.target.value);
      };

    const handleStatusChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setStatus(event.target.value);
      };
    const saveChanges = () => {
        try{
            const data = { 
                jwt: localStorage.getItem('adminJwt'),
                id: id,
                email: emailValue,
                balance: balance,
                password: passwordValue, 
                status: status
            }

            const response = axios.post("https://bigdata.name/UpdateUserADMIN", data);
            alert('Saved!');
            navigate('/userList/1/none_param');
        }
        catch(error){
            alert('error saving changes');
        }

    }

    const tableRows = () => {
        return transactions.map(item => {
            let date = new Date(item.creationDate);
            return(
                <tr key={item.id}>
                    <th className="table-column">{item.id}</th>
                    <th className="table-column">{item.currency}</th>
                    <th className="table-column"><a href={item.addressHandler}>{item.addressHandler}</a></th>
                    <th className="table-column">{item.pending}</th>
                    <th className="table-column">{item.received}$</th>
                    <th className="table-column">{date.toLocaleDateString()}<br></br>
                    {date.toLocaleTimeString()}
                    </th>
                </tr>
            )
        })
    }

    const transactionsTable = () => {
        if (transactions.length > 0){
            return(
                <>
                    <table className="transactions-table">
                        <thead>
                            <tr>
                                <th>Id</th>
                                <th>currency</th>
                                <th>Address handler</th>
                                <th>Pending</th>
                                <th>Received</th>
                                <th>Creation Date</th>
                            </tr>

                        </thead>
                        <tbody>
                            {tableRows()}
                        </tbody>
                    </table>
                </>
            )
        }
        else{
            return(
                <>
                    <div style={{fontSize: '45px', fontWeight: 'lighter', textAlign: 'center'}}>NO TRANSACTIONS WERE MADE! :{'('}</div>
                </>
            )
        }

    }

    useEffect(() => {
        authorizeCheck();
        getUser();
    }, []); 

    return (
        <>
            <select value={status} onChange={handleStatusChange} className="status-menu">
                <option value='active' style={{color: 'green'}}>ACTIVE</option>
                <option value='not active' style={{color: 'darkorange'}}>NOT ACTIVE</option>
                <option value='blocked' style={{color: 'darkred'}}>BLOCKED</option>
            </select>
            <div className="user-info-title">Personal information</div>
            <div style={{height: '1.8px'}} className="separting-line"></div>
            <div style={{width: '50%', marginLeft: '40%'}}>
                <div className="user-info">Id: {id}</div><br></br>
                <div className="user-info">Email:</div>&emsp;
                <input value={emailValue} onChange={handleEmailChange} className="user-info-input"></input>
                <br></br>
                <div className="user-info">Balance:</div>&emsp;
                <input style={{width: '120px'}} value={balance} min={0} onChange={handleBalanceChange} className="user-info-input" type="number"></input><br></br>
            </div>
            <div style={{width: "100%", textAlign: 'center', margin: '20px auto'}}>
                <input value={passwordValue} onChange={handlePasswordChange} className="password-box" placeholder="Set new password..."></input>
                <Link to={'/chat/' + id} className="navigation-button">CHAT</Link>
                <Link to={'/ordersPage/' + id + "/1"} className="navigation-button">ORDERS</Link>
            </div>
            <div style={{height: "60px"}}></div>
            <div className="user-info-title">Transactions</div>
            <div className="separting-line"></div>  
            {transactionsTable()}
            <div onClick={saveChanges} className="save-button">Save Changes</div>
        </>
    )
}

export default UserPage;