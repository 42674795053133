export const validateString = (str: string | undefined) => {
    if (str === undefined)
        return '';
    return str;
  }
  
  export const validateStringWithSpecialSymbols = (str: string | undefined) => {
    if (str === undefined)
        return '';
    return str;
}

export const translateEngRusEsp = (lang: string, english: string, russian: string, spanish: string) => {
    if (lang === 'eng'){
      return english;
    }
    else if (lang === 'rus'){
      return russian;
    }
    else if (lang === 'esp'){
      return spanish;
    }
}

