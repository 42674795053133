import { Link, useNavigate } from 'react-router-dom'
import '../Header/css/ct.css'

const Header = () => {

    const navigate = useNavigate();
    function LogOut() {
        try{
            localStorage.removeItem('adminJwt');
            navigate('/signIn');
            window.location.reload();
        }
        catch(error){
            alert(error);
        }
    }

    const headerLinks = () => {
        if (localStorage.getItem('adminJwt') !== null){
            return(
                <>
                    <Link to='/transactionList/1/none_param' className='header-link'>TRANSACTIONS</Link>
                    <Link to='/userList/1/none_param' className='header-link'>USERS</Link>
                    <Link to='/chatList/1' className='header-link'>CHATS</Link>
                    <Link to='/uploadData' className='header-link'>UPLOAD DATA</Link>
                    <div onClick={LogOut} className='header-link'>LOG OUT</div>
                </>
            )
        }
        else{
            return(
                <>
                    <Link to='/signIn' className='header-link'>SIGN IN</Link>
                </>
            )
        }
    }


    return(
    <>
        <div className="header-frame">
            <Link to='/' className='header-block'>
                INFO<span style={{color: 'limegreen'}}>MARKET</span>
                <span className='admin-symbol'>admin</span>
            </Link>    
            <div className='header-links-container'>
                {headerLinks()}
            </div>
        </div>
    </>)
}

export default Header;