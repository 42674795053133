import './css/ct.css'
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { IPersonPrivateInfo } from '../Interfaces/Interfaces';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { translateEngRusEsp, validateString } from '../Functions/Functions';
import broom from '../Images/broom.jpg';
import { saveAs } from 'file-saver';


const OrdersPage = () => {
    const { id, page } = useParams<{
        id: string;
        page: string;
      }>();  
    const [language, setLanguage] = useState<string>(localStorage.getItem('language') !== null ? localStorage.getItem('language') + '' : 'eng');
    const authorizeCheck = () => {
        if (localStorage.getItem('adminJwt') === null){
            navigate('/signIn');
        }
    }

    const [info, setInfo] = useState<Array<IPersonPrivateInfo>>([
        {
            id: '0',
            ssn: '000-00-0000',
            dob: new Date(1980, 11, 31),
            fullName: 'Thomas Anderson',
            address: 'Michigan Avenue',
            city: 'Chicago',
            state: 'IL',
            zip: '60007',
            country: 'USA',
            phone: '777 77 777',
            email: 'neo@gmail.com',
            dlNumber: '111',
            dmvNumber: '111',
            dlIssueTime: new Date(2010, 11, 31),
            dlExpireTime: new Date(2030, 11, 31)
        }
    ]);
    const [currentPage, setCurrentPage] = useState<number>(isNaN(parseInt(validateString(page))) ? 1 : parseInt(validateString(page)));
    const [countPages, setCountPages] = useState<number>(1);


    const getSearchParams = (num: number) => {
        return {
            year: "",
            fullName: '', 
            city: '', 
            state: '', 
            zip: '', 
            country:'', 
            dl: false,
            page: num,
            jwt: localStorage.getItem('adminJwt'),
            userId: parseInt(id + "")
        };
    }

    
    const getPageCountParams = () => {
        return {
            year: "",
            fullName: '', 
            city: '', 
            state: '', 
            zip: '', 
            country:'', 
            dl: false,
            jwt: localStorage.getItem('adminJwt'),
            userId: parseInt(id + "")
        };
    }

    const setPageInfo = async (num: number) => {
        if (localStorage.getItem('adminJwt') !== null){
            const data = getPageCountParams();
            try{
                const response = await axios.post("https://bigdata.name/GetBoughtPageCountADMIN", data);
                setCountPages(response.data);
                if (response.data !== 0){
                    let val = num;
                    if (num > response.data){
                        val = response.data;
                        setCurrentPage(val);
                    }
                    const data2 = getSearchParams(val);
                    const response2 = await axios.post("https://bigdata.name/GetBoughtPersonsADMIN", data2);
                    setInfo(response2.data);
                }
            }
            catch(error){
                alert(error);
            }
        }

    }

    const isPageEmpty = () => {
        const checkboxes = document.getElementsByClassName('checking');
        if (checkboxes.length === 0 || checkboxes === null){
            //console.log(checkboxes.length, countPages);
            window.location.reload();
        }
    }

    const navigate = useNavigate();

    const displayPublicInfo = (arr: Array<IPersonPrivateInfo>) => {
        if (arr.length > 0 && countPages > 0 && arr !== null)
        {
            return (
                <table className='display-public'>
                    <thead>
                        <tr>
                            <th className='private-column'>{translateEngRusEsp(language, 'Add', 'Добавить', 'Agregar')}</th>
                            <th className='private-column'>SSN</th>
                            <th className='private-column'>{translateEngRusEsp(language, 'DOB', 'Дата рождения', 'Fecha de nacimiento')}</th>
                            <th className='private-column'>{translateEngRusEsp(language, 'Full Name', 'Полное Имя', 'Nombre Completo')}</th>
                            <th className='private-column'>{translateEngRusEsp(language, 'Address', 'Адрес', 'Dirección')}</th>
                            <th className='private-column'>{translateEngRusEsp(language, 'City', 'Город', 'Ciudad')}</th>
                            <th className='private-column'>{translateEngRusEsp(language, 'State', 'Штат', 'Estado')}</th>
                            <th className='private-column'>ZIP</th>
                            <th className='private-column'>{translateEngRusEsp(language, 'Country', 'Страна', 'País')}</th>
                            <th className='private-column'>{translateEngRusEsp(language, 'Phone', 'Телефон', 'Teléfono')}</th>
                            <th className='private-column'>{translateEngRusEsp(language, 'email', 'электронная почта', 'correo electrónico')}</th>
                            <th className='private-column'>{translateEngRusEsp(language, 'DL Number', 'Номер водительского удостоверения', 'Número de licencia de conducir')}</th>
                            <th className='private-column'>{translateEngRusEsp(language, 'DMV Number', 'Номер DMV', 'Número de DMV')}</th>
                            <th className='private-column'>{translateEngRusEsp(language, 'DL Issue Time', 'Время выдачи водительского удостоверения', 'Hora de emisión de la licencia de conducir')}</th>
                            <th className='private-column'>{translateEngRusEsp(language, 'DL Expire Time', 'Время истечения срока действия водительского удостоверения', 'Hora de vencimiento de la licencia de conducir')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            arr.map((person, index) => {
                                let birth = new Date(person.dob);
                                let birthDate: string = `${birth.getMonth()}/${birth.getDay()}/${birth.getFullYear()}`;
                                let issTime = new Date(person.dlIssueTime);
                                let issTimeDate: string  = person.dlIssueTime === undefined ? 'N/A' : `${issTime.getMonth()}/${issTime.getDay()}/${issTime.getFullYear()}`;
                                let expTime = new Date(person.dlExpireTime);
                                let expTimeDate: string  = person.dlExpireTime === undefined ? 'N/A' : `${expTime.getMonth()}/${expTime.getDay()}/${expTime.getFullYear()}`;
                                //console.log(person.dlIssueTime);
                                return (
                                    <tr key={index}>
                                        <th className='table-title'>
                                            <input id={person.id} className='checking' type='checkbox'></input>
                                        </th>
                                        <th className='table-title-private'>{person.ssn}</th>
                                        <th className='table-title-private'>{birthDate}</th>
                                        <th className='table-title-private'>{person.fullName}</th>
                                        <th className='table-title-private'>{person.address}</th>
                                        <th className='table-title-private'>{person.city}</th>
                                        <th className='table-title-private'>{person.state}</th>
                                        <th className='table-title-private'>{person.zip}</th>
                                        <th className='table-title-private'>{person.country}{person.country === 'USA' ? '🇺🇸' : '🇨🇦'}</th>
                                        <th className='table-title-private'>{person.phone === null ? 'N/A' : person.phone}</th>
                                        <th className='table-title-private'>{person.email === null ? 'N/A' : person.email}</th>
                                        <th className='table-title-private'>{person.dlNumber === null ? 'N/A' : person.dlNumber}</th>
                                        <th className='table-title-private'>{person.dmvNumber === null ? 'N/A' : person.dmvNumber}</th>
                                        <th className='table-title-private'>{issTimeDate}</th>
                                        <th className='table-title-private'>{expTimeDate}</th>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            )
        }
        else{
            return(
                <>
                {/* <div style={{textAlign: 'center'}}><img className='broom-im' src={broom}></img></div>
                <div style={{textAlign: 'center', fontSize: '50px', marginTop: '50px'}}>
                    <Link className='buy-button' to='/searchPage/1'>{translateEngRusEsp(language, 'GO SHOPPING', 'ПОКУПАТЬ', 'IR DE COMPRAS')}</Link>
                </div> */}
                </>

            )
        }
    }

    const selectAll = () => {
        const checkboxes = document.getElementsByClassName('checking');
        for (let i = 0; i < checkboxes.length; i++) {
          const checkbox = checkboxes[i] as HTMLInputElement;
          checkbox.checked = true;
        }
    };

    const Clear = () => {
        const checkboxes = document.getElementsByClassName('checking');
        for (let i = 0; i < checkboxes.length; i++) {
          const checkbox = checkboxes[i] as HTMLInputElement;
          checkbox.checked = false;
        }
    }

    const getFileText = () => {
        const checkboxes = document.getElementsByClassName('checking');
        let list: string[] = [];
        let fileText: string = '';
        for (let i = 0; i < checkboxes.length; i++) {
            const checkbox = checkboxes[i] as HTMLInputElement;
            if (checkbox.checked)
            {
                list.push(checkbox.id);
                fileText += 'Full Name: ' + (info.find(x => x.id === checkbox.id))?.fullName + '\n';    
                fileText += 'SSN: ' + (info.find(x => x.id === checkbox.id))?.ssn + '\n';    
                fileText += 'DOB: ' +new Date((info.find(x => x.id === checkbox.id))?.dob + '').toDateString() + '\n';    
                fileText += 'Address: ' + (info.find(x => x.id === checkbox.id))?.address + '\n';    
                fileText += 'City: ' + (info.find(x => x.id === checkbox.id))?.city + '\n';    
                fileText += 'State: ' + (info.find(x => x.id === checkbox.id))?.state + '\n';    
                fileText += 'Zip: ' + (info.find(x => x.id === checkbox.id))?.zip + '\n';    
                fileText += 'Country: ' + (info.find(x => x.id === checkbox.id))?.country + '\n'; 
                if (((info.find(x => x.id === checkbox.id))?.phone + '') !== 'null'){
                    fileText += 'Phone: ' + (info.find(x => x.id === checkbox.id))?.phone + '\n'; 
                } 
                if (((info.find(x => x.id === checkbox.id))?.email + '') !== 'null'){
                    fileText += 'Email: ' + (info.find(x => x.id === checkbox.id))?.email + '\n'; 
                } 
                if (((info.find(x => x.id === checkbox.id))?.dlNumber + '') !== 'null'){
                    fileText += 'DL Number: ' + (info.find(x => x.id === checkbox.id))?.dlNumber + '\n'; 
                } 
                if (((info.find(x => x.id === checkbox.id))?.dmvNumber + '') !== 'null'){
                    fileText += 'DMV Number: ' + (info.find(x => x.id === checkbox.id))?.dmvNumber + '\n'; 
                } 
                if (((info.find(x => x.id === checkbox.id))?.dlIssueTime) !== undefined){
                    fileText += 'DL Issue Time: ' + (info.find(x => x.id === checkbox.id))?.dlIssueTime + '\n'; 
                } 
                if (((info.find(x => x.id === checkbox.id))?.dlExpireTime) !== undefined){
                    fileText += 'DL Expire Time: ' + (info.find(x => x.id === checkbox.id))?.dlExpireTime + '\n'; 
                } 
                fileText += '-------\n';
            }
        }
        //console.log(fileText);
        const blob = new Blob([fileText], { type: 'text/plain;charset=utf-8' });
        saveAs(blob, 'infomarket.txt');
    }

    const pageExist = (num: number) => {
        if (num > 0){
            if (num <= countPages){
                return true;
            }
        }
    }

    const openPage = (num: number) => {
        if (num > 0){
            if (num <= countPages){
                navigate(`/ordersPage/${id}/${num}`);
                window.location.reload();
            }
        }
    }

    const pagination = (num: number) => {
        if (countPages > 0){
            if (countPages <= 5){
                return(
                    <>
                        <div onClick={() => openPage(num - 1)} style={{display: pageExist(num - 1) ? 'inline-block' : 'none'}} className='switch-btn arrow-btn'>{'<'}</div>
                        <div onClick={() => openPage(1)} className='switch-btn'>1</div>
                        <div onClick={() => openPage(2)} style={{display: pageExist(2) ? 'inline-block' : 'none'}} className='switch-btn'>2</div>
                        <div onClick={() => openPage(3)} style={{display: pageExist(3) ? 'inline-block' : 'none'}} className='switch-btn'>3</div>
                        <div onClick={() => openPage(4)} style={{display: pageExist(4) ? 'inline-block' : 'none'}} className='switch-btn'>4</div>
                        <div onClick={() => openPage(5)} style={{display: pageExist(5) ? 'inline-block' : 'none'}} className='switch-btn'>5</div>
                        <div onClick={() => openPage(num + 1)} style={{display: pageExist(num + 1) ? 'inline-block' : 'none'}} className='switch-btn arrow-btn'>{'>'}</div>
                    </> 
                )
            }
            else if(countPages > 5){
                if ((countPages - num) > 5){
                    if (num < 20){
                        return(
                        <>
                            <div className='switch-btn arrow-btn'>{'<'}</div>
                            <div className='switch-btn'>{num - 2}</div>
                            <div className='switch-btn'>{num - 1}</div>
                            <div className='switch-btn'>{num}</div>
                            <div className='switch-btn'>{num + 1}</div>
                            <div className='switch-btn'>{num - 2}</div>
                            <div className='switch-btn arrow-btn'>{'>'}</div>
                        </> 
                        )
                    }
                    else{
                        return(
                            <>
                                <div className='switch-btn arrow-btn'>{'<'}</div>
                                <div className='switch-btn'>...</div>
                                <div className='switch-btn'>{num - 1}</div>
                                <div className='switch-btn'>{num}</div>
                                <div className='switch-btn'>{num + 1}</div>
                                <div className='switch-btn'>{num - 2}</div>
                                <div className='switch-btn arrow-btn'>{'>'}</div>
                            </> 
                            )
                    }
                    
                }
            }
            else{
                return(
                    <>
                        <div className='switch-btn arrow-btn'>{'<'}</div>
                        <div className='switch-btn'>{countPages - 4}</div>
                        <div className='switch-btn'>{countPages - 3}</div>
                        <div className='switch-btn'>{countPages - 2}</div>
                        <div className='switch-btn'>{countPages - 1}</div>
                        <div className='switch-btn'>{countPages}</div>
                        <div style={{display: pageExist(num + 1) ? 'inline-block' : 'none'}} className='switch-btn arrow-btn'>{'>'}</div>
                    </> 
                )
            }
        }


    }


    useEffect(() => {
        authorizeCheck();
        // setStartValues();
        setPageInfo(currentPage);
    }, []); 

    return (
        <>
            <br></br>
            <br></br>
            <div onClick={selectAll} className='manage-button'>{translateEngRusEsp(language, 'Select All', 'Выбрать все', 'Seleccionar todo')}</div>
            <div onClick={Clear} style={{borderColor: 'darkred', color: 'darkred'}} className='manage-button'>{translateEngRusEsp(language, 'Clear', 'Очистить', 'Borrar')}</div>
            <div onClick={getFileText} className='manage-button buy-items'>Get File Text</div>
            <div className='table-container'>
                {displayPublicInfo(info)}
            </div> 
            <div className='pagination-container'>
                {pagination(currentPage)}
            </div> 
        </>
        
    )
}

export default OrdersPage;