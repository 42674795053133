import axios from "axios";
import { useEffect, useState } from "react";
import { IChat, IUser } from "../../Interfaces/Interfaces";
import './css/ct.css';
import user_logo from '../../Images/default_account_logo_png.png';
import { Link, useNavigate, useParams } from "react-router-dom";

const ChatList = () => {
    const { page } = useParams<{
        page: string;
      }>();
    const [chats, setChats] = useState<Array<IChat>>([]);
    const [pageCount, setPageCount] = useState<number>(5);
    const [currentPage, setCurrentPage] = useState<number>(parseInt(page + ''));

    const navigate = useNavigate();

    const getChats = async () => {
        const data = {
            data: localStorage.getItem('adminJwt'),
            page: currentPage,
        }
        const response = await axios.post('https://bigdata.name/getAllChats', data);
        setChats(response.data.chats);
        // setCurrentPage(response.data.page);
        setPageCount(response.data.pageCount);
        setCurrentPage(response.data.currentPage);
    }
    const authorizeCheck = () => {
        if (localStorage.getItem('adminJwt') === null){
            navigate('/signIn');
        }
    }

    const displayChats = () => {
        return chats.map(chat => {
            return(
                <Link key={chat.id} to={'/chat/' + chat.id} className="chat-preview">
                    <div className="img-container">
                        <img src={user_logo} className="user-image"></img>
                    </div>
                    <div>
                        id: {chat.id}<br></br>
                        {chat.email}
                    </div>
                    <div style={{ fontSize: '35px', marginLeft: 'auto', color: 'limegreen'}}>
                        {chat.unread ? "UNREAD MESSAGES" : ""}
                    </div>
                </Link>
            )
        })
    }

    const pageAvailable = (num: number, symbol: string) => {
        let char = `${num}`;
        if (symbol !== ""){
            char = symbol;
        }
        if (num <= pageCount && num !== 0){
            return(
                <div onClick={() => RedirectToPage(navigatePageString(parseInt(char)))} className='page-counter-elem'>{char}</div>
            )
        }
    }

    const pageCounter = () => {
        if (currentPage < 5)
        {
            return (
                <div className='page-counter-container'>
                    {pageAvailable(currentPage - 1,'<')}
                    {pageAvailable(1,'1')}
                    {pageAvailable(2,'2')}
                    {pageAvailable(3,'3')}
                    {pageAvailable(4,'4')}
                    {pageAvailable(5,'5')}
                    {pageAvailable(currentPage + 1,'>')}
                </div>
            )
        }
        else{
            if((pageCount - currentPage) > 5){
                return(
                    <div className='page-counter-container'>
                        {pageAvailable(currentPage - 1,'<')}
                        {pageAvailable(currentPage - 2,'')}
                        {pageAvailable(currentPage - 1,'')}
                        {pageAvailable(currentPage,'')}
                        {pageAvailable(currentPage + 1,'')}
                        {pageAvailable(currentPage + 2,'')}
                        {pageAvailable(currentPage + 1,'>')}
                    </div>
                )
                
            }
            else{
                return(
                    <div className='page-counter-container'>
                        {pageAvailable(currentPage - 1,'<')}
                        {pageAvailable(currentPage - 5,'')}
                        {pageAvailable(currentPage - 4,'')}
                        {pageAvailable(currentPage - 3,'')}
                        {pageAvailable(currentPage - 2,'')}
                        {pageAvailable(pageCount - 1,'')}
                        {pageAvailable(currentPage + 1,'>')}
                    </div>
                )
            }

        }
    }

    const RedirectToPage = (url: string) => {
        navigate(url);
        window.location.reload();
    }

    const navigatePageString = (num: number) => {
        return "/chatList/" + num
    }

    useEffect(() => {
        authorizeCheck();
        getChats();
        //console.log(currentPage);
    }, []); 

    return (
        <>
            {displayChats()}
            {pageCounter()}
        </>
    )
}

export default ChatList;