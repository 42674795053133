import { useState } from 'react';
import './css/ct.css';
import axios from 'axios';

interface ITemporaryValue{
    arr: string[];
}

interface IPerson{
    SSN: string;
    DOB: string;
    FullName: string;
    Address: string;
    City: string;
    State: string;
    Country: string;
    ZIP: string;
    Phone: string;
    Email: string;
    DLNumber: string;
    DLIssueDate: string;
    DLExpireDate: string;
}

const UploadData = () => {

    const [lines, setLines] = useState<Array<string[]>>([]);

    const [separateSymbol, setSeparateSymbol] = useState<string>('');
    const [dateSeparateSymbol, setDateSeparateSymbol] = useState<string>('/');
    // Number of columns - 12
    const [selectedColumns, setSelectedColumns] = useState<string[]>([
        'SSN', 
        "DOB", 
        "Full Name", 
        "Address", 
        "City", 
        "State", 
        "Country", 
        "ZIP", 
        "Phone", 
        "Email",
        "DL Number",
        "DL Issue Date",
        "DL Expire Date"
    ]);

    const [selectDateFormat1, setSelectDateFormat1] = useState<string>('mm');
    const [selectDateFormat2, setSelectDateFormat2] = useState<string>('dd');
    const [selectDateFormat3, setSelectDateFormat3] = useState<string>('yy');
    

    ///// WORKING WITH FILE
    const [fileContent, setFileContent] = useState<string | null>(null);
    const [firstRow, setFirstRow] = useState<string>('');


    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
          readFileContents(file);
        }
      };

    const readFileContents = (file: File) => {
        const reader = new FileReader();
    
        reader.onload = (event) => {
          if (event.target?.result) {
            const content = event.target.result as string;
            setFileContent(content);
            const lines = content.split('\n');
            setFirstRow(lines[0]);
          }
        };
    
        reader.readAsText(file);
      };


    /////

    const ParseText = () => {
        setLines([]);
        const content = fileContent?.split('\n');
        content?.forEach(item => {
            if (separateSymbol !== ''){
                const line = item.split(separateSymbol);
                let value: string[] = new Array(13).fill('');
                line.forEach((elem, index) => {
                    if(elem !== 'N/A' && elem !== 'N/A\r' && elem !== '\r')
                        value[index] = elem;
                });
                setLines((prevValue) => [...prevValue, value]);
            }
        })
    }

    const generateColums = () => {
        return lines.map((line, index)=> {
            return(
                <tr key={index}>
                    {line.map((column, columnIndex) => (
                        <td key={columnIndex + 'column'}>{column}</td>
                    ))}
                </tr>
            )
        })
    }

    const handleSeparateSymbolChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSeparateSymbol(event.target.value);
    }

    const handleDateSeparateSymbolChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDateSeparateSymbol(event.target.value);
    }

    const handleDateFormatChange1 = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectDateFormat1(event.target.value);
    }
    const handleDateFormatChange2 = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectDateFormat2(event.target.value);
    }
    const handleDateFormatChange3 = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectDateFormat3(event.target.value);
    }
  
    const handleColumnChange = (index: number) => (event: React.ChangeEvent<HTMLSelectElement>) => {
      const selectedValue = event.target.value;
      setSelectedColumns((prevSelectedColumns) => {
        const updatedColumns = [...prevSelectedColumns];
        updatedColumns[index] = selectedValue;
        return updatedColumns;
      });
    };

    const generateHeaders = () => {
        const arr = [1,2,3,4,5,6,7,8,9,10,11,12,13];
        return arr.map((elem, index) =>{
            return(
                <th key={index + 'col'}>
                    <select key={index} className='column-selector' value={selectedColumns[index]} onChange={handleColumnChange(index)}>
                        <option>SSN</option>
                        <option>DOB</option>
                        <option>Full Name</option>
                        <option>Address</option>
                        <option>City</option>
                        <option>State</option>
                        <option>Country</option>
                        <option>ZIP</option>
                        <option>Phone</option>
                        <option>Email</option>
                        <option>DL Number</option>
                        <option>DL Issue Date</option>
                        <option>DL Expire Date</option>
                    </select>
                </th>
            )
            
        })
    }

    const convertDateToString = (dateString: string) => {
        let dateLines = dateString.split(dateSeparateSymbol);
        let day = '';
        let month = '';
        let year = '';
        if (selectDateFormat1 === 'dd'){
            day = dateLines[0];
        }
        else if(selectDateFormat1 === 'mm'){
            month = dateLines[0];
        }
        else{
            year = dateLines[0];
        }

        if (selectDateFormat2 === 'dd'){
            day = dateLines[1];
        }
        else if(selectDateFormat2 === 'mm'){
            month = dateLines[1];
        }
        else{
            year = dateLines[1];
        }

        if (selectDateFormat3 === 'dd'){
            day = dateLines[2];
        }
        else if(selectDateFormat3 === 'mm'){
            month = dateLines[2];
        }
        else{
            year = dateLines[2];
        }

        return `${day}-${month}-${year}`;
    }

    ////SENDIND DATA

    const convertData = () => {
        let data: IPerson[] = [];
        lines.forEach(line => {
            let person: IPerson = {
                SSN: '',
                DOB: '',
                FullName: '',
                Address: '',
                City: '',
                State: '',
                Country: '',
                ZIP: '',
                Phone: '',
                Email: '',
                DLNumber: '',
                DLIssueDate: '',
                DLExpireDate: '',
            };
            line.forEach((item, index) => {
                let col = selectedColumns[index];
                switch (col){
                    case 'SSN':{
                        person.SSN = item;
                        break;
                    }
                    case 'DOB':{
                        let date: Date = new Date(item);
                        if (item !== '')
                            person.DOB = convertDateToString(item);
                        break;
                    }
                    case 'Full Name' :{
                        person.FullName = item;
                        break;
                    }
                    case 'Address':{
                        person.Address = item;
                        break;
                    }
                    case 'City':{
                        person.City = item;
                        break;
                    }
                    case 'State':{
                        person.State = item;
                        break;
                    }
                    case 'Country':{
                        person.Country = item;
                        break;
                    }
                    case 'ZIP':{
                        person.ZIP = item;
                        break;
                    }
                    case 'Phone':{
                        person.Phone = item;
                        break;
                    }
                    case 'Email':{
                        person.Email = item;
                        break;
                    }
                    case 'DL Number':{
                        person.DLNumber = item;
                        break;
                    }
                    case 'DL Issue Date':{
                        let date: Date = new Date(item);
                        if (item !== '')
                            person.DLIssueDate =  convertDateToString(item);
                        break;
                    }
                    case 'DL Expire Date':{
                        let date: Date = new Date(item);
                        if (item !== '')
                            person.DLExpireDate =  convertDateToString(item);
                        break;
                    }
                }
            })
            data.push(person);
           
        })
        return data;
    }

    const sendData = async () => {
        try{
            let info = convertData();
            const data = {
                jwt: localStorage.getItem('adminJwt'),
                persons: info
            };
            const response = await axios.post("https://bigdata.name/UploadData", data);
            //console.log(convertData());
            alert('Data Saved!!!');
        }
        catch(error){
            alert(error);
        }
    }

    return (
        <>
            <div className="uploading-container">
                <div className='main-title'>SELECT FILE</div>
                <input className='file-selector' onChange={handleFileChange} type='file' accept='.txt'></input>
                <div style={{height: '40px'}}></div>

                <div className='main-title'>File Text Line</div>
                <input disabled value={firstRow} onChange={() => {}} className='text-input2' type='text' placeholder='...'></input>
                <div style={{height: '40px'}}></div>
                
                <div className='main-title'>Separate symbol</div>
                <input value={separateSymbol} onChange={handleSeparateSymbolChange} className='text-input' type='text' maxLength={3} placeholder='...'></input>
                <div style={{height: '40px'}}></div>
                
                <div onClick={ParseText} className='parse-button'>PARSE</div>

                <div style={{height: '40px'}}></div>
                <div className='main-title'>Date separate symbol</div>
                <input value={dateSeparateSymbol} onChange={handleDateSeparateSymbolChange} className='text-input' type='text' maxLength={2} placeholder='...'></input>
                <div style={{height: '40px'}}></div>
                <div className='main-title'>Date format</div>
                <select value={selectDateFormat1} onChange={handleDateFormatChange1} className='date-selector'>
                    <option>dd</option>
                    <option>mm</option>
                    <option>yy</option>
                </select>
                <select value={selectDateFormat2} onChange={handleDateFormatChange2} className='date-selector'>
                    <option>dd</option>
                    <option>mm</option>
                    <option>yy</option>
                </select>
                <select value={selectDateFormat3} onChange={handleDateFormatChange3} className='date-selector'>
                    <option>dd</option>
                    <option>mm</option>
                    <option>yy</option>
                </select>
                <div style={{height: '40px'}}></div>
                <div style={{height: '40px'}}></div>
                <div className='select-container'>

                </div>
                <div style={{height: '40px'}}></div>
                <div style={{width: '100%'}}>
                    <div onClick={sendData} className='send-data-button'>SEND DATA</div>
                </div>
                <div style={{height: '40px'}}></div>
                <div className='main-title'>Information output</div>
                <table className='persons-table'>
                    <thead>
                        <tr>
                            {generateHeaders()}
                        </tr> 
                    </thead>
                    <tbody>
                        {generateColums()}
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default UploadData;